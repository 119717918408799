<template>
  <v-card min-height="800px">
    <v-card-title class="justify-center">Veli Bilgilerim</v-card-title>
    <v-container>
      <v-list v-if="!loading">
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-content-between align-center">
            <h5>İsim Soyisim</h5>
            <v-chip label outlined color="primary">{{ editedItem.FullName }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-content-between align-center">
            <h5>Email</h5>
            <v-chip label outlined color="primary"
              >{{ editedItem.Email
              }}<v-icon class="ml-2" @click="editItem(editedItem)"> mdi-pencil </v-icon></v-chip
            >
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.PhoneNumber">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <h5>Telefon Numarası</h5>
            <v-chip label outlined color="primary">{{ editedItem.PhoneNumber }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.Students && editedItem.Students.length > 0">
          <v-list-item-title>
            <h5>Öğrenciler</h5>
          </v-list-item-title>
          <p class="mt-2" v-for="(student, i) in editedItem.Students" :key="i">
            <v-chip label outlined color="green">
              {{ student.FirstName }} {{ student.LastName }}</v-chip
            >
          </p>
        </v-list-item-content>
      </v-list>
      <v-dialog v-model="dialog" max-width="300px" persistent>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.Email"
                    outlined
                    hide-details
                    dense
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> İptal </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Kaydet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'veli-profil-duzenle',
  data() {
    return {
      editedItem: {},
      item: {},
      loading: true,
      dialog: false,
      defaultItem: {}
    }
  },
  mounted() {
    this.getUserFromApi()
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY hh:mm') : ''
    }
  },
  methods: {
    editItem(item) {
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem)
      })
    },
    save() {
      ApiService.setHeader()
      ApiService.put('api/customer/email', this.item)
        .then(() => {
          this.close()
          this.getUserFromApi()
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getUserFromApi() {
      ApiService.setHeader()
      ApiService.post('api/customer/profile', {})
        .then((data) => {
          this.editedItem = data.data
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
    }
  }
}
</script>
