var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "min-height": "800px" } },
    [
      _c("v-card-title", { staticClass: "justify-center" }, [
        _vm._v("Veli Bilgilerim"),
      ]),
      _c(
        "v-container",
        [
          !_vm.loading
            ? _c(
                "v-list",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass:
                            "d-flex justify-content-between align-center",
                        },
                        [
                          _c("h5", [_vm._v("İsim Soyisim")]),
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                label: "",
                                outlined: "",
                                color: "primary",
                              },
                            },
                            [_vm._v(_vm._s(_vm.editedItem.FullName))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass:
                            "d-flex justify-content-between align-center",
                        },
                        [
                          _c("h5", [_vm._v("Email")]),
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                label: "",
                                outlined: "",
                                color: "primary",
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.editedItem.Email)),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(_vm.editedItem)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editedItem.PhoneNumber
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("h5", [_vm._v("Telefon Numarası")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.editedItem.PhoneNumber))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.Students && _vm.editedItem.Students.length > 0
                    ? _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c("h5", [_vm._v("Öğrenciler")]),
                          ]),
                          _vm._l(
                            _vm.editedItem.Students,
                            function (student, i) {
                              return _c(
                                "p",
                                { key: i, staticClass: "mt-2" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        outlined: "",
                                        color: "green",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(student.FirstName) +
                                          " " +
                                          _vm._s(student.LastName)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "300px", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      "hide-details": "",
                                      dense: "",
                                      label: "Email",
                                    },
                                    model: {
                                      value: _vm.item.Email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "Email", $$v)
                                      },
                                      expression: "item.Email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" İptal ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.save },
                        },
                        [_vm._v(" Kaydet ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }